<template>
  <div v-if="loading">
    <Loader />
  </div>
  <div v-else class="noselect margins3" style="align-self: start">
    <div>
      <h3 class="pointer" @click="followLink('/peoplelist')">Сотрудники</h3>
      <h3 class="pointer" @click="followLink('/pointlist')">Локации</h3>
      <h3 class="pointer" @click="followLink('/joblist')">Должности</h3>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from "vue";
import { useRouter } from "vue2-helpers/vue-router";
import { userDataGet } from "@/components-js/requestSrv";
import Loader from "@/components/Loader.vue";

export default defineComponent({
  name: "Reference",
  components: { Loader },
  setup() {
    const router = useRouter();
    const user = ref({});
    const loading = ref(true);
    const followLink = href => {
      router.push(href);
    };

    onMounted(async () => {
      user.value = await userDataGet();
      loading.value = false;
    });
    return {
      user,
      loading,
      followLink,
    };
  },
});
</script>
